// Import libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';


// Create component
const SEO = ({ lang, meta, title, description }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    titleTemplate
                    description
                    author
                }
            }
        }
    `);
    

    // By default use prop values otherwise use site meta data value
    const metaDescription = description || site.siteMetadata.description;

    
    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={site.siteMetadata.titleTemplate}
            meta={[
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1'
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    );
};


// Assign prop types to component
SEO.propTypes = {
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    description: PropTypes.string
};


// Assign default prop values
SEO.defaultProps = {
    lang: 'en',
    meta: [],
    title: null,
    description: null
};


// Export component by default
export default SEO;